.error {
  padding-top: 76px;
  @include screen(laptop) {
    padding-top: 100px;
  }
  &__wrapper {
    display: flex;
    min-height: calc(100dvh - 136px);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @include screen(tablet) {
      min-height: calc(100dvh - 192px);
      justify-content: center;
    }
    @include screen(laptop) {
      width: 100%;
    }
    h1 {
      margin-bottom: 16px;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 36px;
      line-height: 40px;
      @include screen(tablet) {
        margin-bottom: 28px;
      }
      @include screen(laptop) {
        font-size: 60px;
        line-height: 1;
      }
    }
    a {
      border-bottom: 2px solid transparent;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: $blue-500;
      @include screen(tablet) {
        font-size: 16px;
        line-height: 24px;
      }
      @include screen(laptop) {
        transition: border-color $transition-default;
        &:hover {
          border-color: $blue-500;
        }
      }
    }
  }
}