@mixin screen($breakpoint) {
  @if $breakpoint == desktop {
    @media only screen and (min-width: 1536px) {
      @content;
    }
  }

  @if $breakpoint == laptop {
    @media only screen and (min-width: 1280px) {
      @content;
    }
  }

  @if $breakpoint == tablet-2x {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (min-width: 375px) {
      @content;
    }
  }
}

@mixin font-xs {
  font-size: 12px;
  line-height: 18px;
}

@mixin font-sm {
  font-size: 14px;
  line-height: 21px;
}

@mixin font-base {
  font-size: 16px;
  line-height: 24px;
}

@mixin font-lg {
  font-size: 18px;
  line-height: 27px;
}

@mixin font-xl {
  font-size: 20px;
  line-height: 30px;
}

@mixin font-2xl {
  font-size: 24px;
  line-height: 36px;
}

@mixin font-3xl {
  font-size: 30px;
  line-height: 45px;
}

@mixin font-4xl {
  font-size: 36px;
  line-height: 42px;
}

@mixin font-5xl {
  font-size: 48px;
  line-height: 64px;
}

@mixin font-6xl {
  font-size: 60px;
  line-height: 70px;
}

@mixin font-7xl {
  font-size: 72px;
  line-height: 108px;
}

@mixin font-8xl {
  font-size: 96px;
  line-height: 144px;
}

@mixin font-9xl {
  font-size: 128px;
  line-height: 192px;
}

@mixin line-clamp-auto {
  display: -webkit-box;
  -webkit-line-clamp: inherit;
  -webkit-box-orient: vertical;

  overflow: hidden;
}

@mixin line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  overflow: hidden;
}