.case {
  position: relative;
  border-radius: 6px;
  transition: transform $transition-default;
  @include screen(laptop) {
    &:hover {
      transform: translateY(-8px);
    }
  }
  &:hover {
    i {
      @include screen(laptop) {
        color: $white;
      }
    }
    .case__bg {
      opacity: 0.75;
    }
    .case__content {
      opacity: 1;
    }
  }
  &__image {
    border-radius: 6px;
    img {
      height: 100%;
      aspect-ratio: 16 / 9;
      width: 100%;
      border: 1px solid $gray-200;
      border-radius: 6px;
      object-fit: cover;
      transition: filter 0.3s;
      object-position: top;
    }
  }
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 6px;
    z-index: 1;
    display: none;
    height: 100%;
    width: 100%;
    opacity: 0;
    background-color: $black;
    @include screen(laptop) {
      display: block;
      transition: opacity $transition-default;
    }
  }
  h3 {
    margin-bottom: 4px;
    font-size: 20px;
    line-height: 28px;
    @include screen(laptop) {
      margin-bottom: 8px;
      font-size: 24px;
      line-height: 32px;
      color: $white;
    }
  }
  &__content {
    position: relative;
    left: 0;
    top: 0;
    z-index: 1;
    border-radius: 6px;
    padding-right: 64px;
    padding-top: 8px;
    @include screen(laptop) {
      position: absolute;
      height: 100%;
      width: 100%;
      padding: 16px;
      opacity: 0;
      transition: opacity $transition-default;
    }
    i,
    span {
      position: absolute;
      right: 0;
      top: 8px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      @include screen(laptop) {
        bottom: 16px;
        right: 16px;
        top: auto;
        color: $white;
      }
    }
    span {
      @include screen(laptop) {
        line-height: 16px;
      }
    }
    p {
      font-size: 14px;
      line-height: 20px;
      @include screen(laptop) {
        font-size: 16px;
        line-height: 24px;
        color: $white;
      }
    }
  }
  &__pin {
    i {
      position: absolute;
      right: 16px;
      top: 12px;
      z-index: 40;
      transform: rotate(45deg);
      font-size: 20px;
      line-height: 28px;
      transition: color $transition-default;
    }
  }
}