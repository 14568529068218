@import "./utils/reset";

/*
  Шрифты и типография
*/
@import "./base/fonts";
@import "./base/typography";

/*
  Базовые стили и утилиты
*/
@import "./base/variables";
@import "./utils/mixins";
@import "./base/global";

/*
  Миксины
*/
@import "./mixins/case";
@import "./mixins/header";

/*
  Компоненты
*/
@import "./components/404";
@import "./components/hero";
@import "./components/cases";
@import "./components/callback";
@import "./components/footer";
@import "./components/policy";