.header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  background-color: $white;
  transition: box-shadow $transition-default;
  &.active {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -2px rgba(0, 0, 0, 0.1);
  }
  &__links {
    display: flex;
    align-items: center;
    li {
      display: flex;
      align-items: center;
      &:last-child {
        margin-right: 0;
        a {
          padding-right: 0;
        }
      }
      a {
        padding: 6px;
        i {
          @include screen(laptop) {
            transition: color $transition-default;
            &:hover {
              color: $blue-500;
            }
          }
          @include screen(tablet) {
            font-size: 20px;
            line-height: 28px;
          }
        }
        svg {
          width: 16px;
          @include screen(tablet) {
            width: auto;
          }
          circle {
            transition: $transition-default;
          }
          &:hover {
            circle {
              fill: $blue-500;
            }
          }
        }
      }
    }
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    @include screen(tablet) {
      padding: 28px 0;
    }
    nav {
      display: flex;
      align-items: center;
      a {
        margin-right: 16px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        transition: color $transition-default;
        &.active {
          color: $blue-500;
        }
        @include screen(laptop) {
          font-size: 16px;
          line-height: 24px;
          &:hover {
            color: $blue-500;
          }
        }
      }
    }
  }
}