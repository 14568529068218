$transition-default: 0.3s;
$transition-slow: 0.8s;

$container-padding: 16px;

$screen-desktop: 1536px;
$screen-laptop: 1280px;
$screen-tablet-2x: 1024px;
$screen-tablet: 768px;

$mobile-wrapper: 414px;

$white: #ffffff;
$black: #000000;

$slate-50: #f8fafc;
$slate-800: #1e293b;
$slate-900: #0f172a;

$orange-700: #c2410c;

$yellow-300: #fde047;

$gray-900: #111928;
$gray-800: #1f2a37;
$gray-700: #374151;
$gray-500: #6b7280;
$gray-400: #9ca3af;
$gray-300: #d1d5db;
$gray-200: #e5e7eb;
$gray-100: #f3f4f6;
$gray-50: #f9fafb;

$blue-900: #233876;
$blue-700: #1a56db;
$blue-600: #1c64f2;
$blue-500: #3f83f8;
$blue-400: #76a9fa;
$blue-100: #e1effe;

$teal-900: #014451;
$teal-600: #047481;
$teal-400: #16bdca;
$teal-100: #d5f5f6;

$purple-900: #4a1d96;
$purple-600: #7e3af2;
$purple-400: #ac94fa;
$purple-100: #edebfe;

$green-900: #014737;
$green-400: #31c48d;

$red-500: #ef4444;
$red-600: #e02424;