.cases {
  padding-top: 108px;
  h2 {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    @include screen(mobile) {
      font-size: 24px;
      line-height: 28px;
    }
    @include screen(tablet) {
      font-size: 36px;
      line-height: 40px;
    }
    @include screen(laptop) {
      font-size: 60px;
      line-height: 1;
    }
  }
  &__wrapper {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
    @include screen(tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include screen(laptop) {
      gap: 20px;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}