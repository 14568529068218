.footer {
  font-size: 12px;
  line-height: 16px;
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    @include screen(tablet) {
      padding: 36px 0;
    }
  }
  &__items {
    display: flex;
    justify-content: flex-end;
    & span {
      display: inline-block;
      @include screen(tablet) {
        font-size: 14px;
        line-height: 20px;
      }
      a {
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        @include screen(laptop) {
          transition: color $transition-default;
          &:hover {
            color: $blue-500;
          }
        }
        i {
          margin-left: 4px;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}