// base icon class definition
// -------------------------

.#{$fa-css-prefix} {
  font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
  font-weight: var(--#{$fa-css-prefix}-style, #{$fa-style});
}

.#{$fa-css-prefix},
.fas,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin,
.fad,
.fa-duotone,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--#{$fa-css-prefix}-display, #{$fa-display});
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}

%fa-icon {
  @include fa-icon;
}
