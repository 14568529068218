.privacy-policy {
  padding-top: 108px;
  h2 {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    @include screen(mobile) {
      font-size: 24px;
      line-height: 28px;
    }
    @include screen(tablet) {
      font-size: 36px;
      line-height: 40px;
    }
    @include screen(laptop) {
      font-size: 60px;
      line-height: 1;
    }
  }
  &__wrapper {
    margin-top: 32px;
    ol {
      counter-reset: item;

      margin-top: 8px;
      &:first-child {
        margin-top: 0;
      }

      li {
        position: relative;

        margin-top: 12px;
        margin-left: 8px;
        font-size: 16px;
        line-height: 24px;

        font-weight: 400;
        &:first-child {
          margin-top: 0;
        }
        ol {
          &:first-child {
            margin-top: 12px;
          }
          li {
            &:first-child {
              margin-top: 12px;
            }
          }
        }
        ul {
          &:first-child {
            margin-top: 12px;
          }
          li {
            &:first-child {
              margin-top: 12px;
            }
          }
        }
        h3 {
          display: inline;
        }
        h4 {
          margin-top: 12px;
          font-size: 20px;
          line-height: 30px;
          &:first-child {
            margin-top: 0;
          }
        }
        p {
          margin-top: 12px;

          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
        a {
          color: $blue-600;
          border-bottom: 1px solid transparent;
          @include screen(laptop) {
            transition: border-color $transition-default;
            &:hover {
              border-color: $blue-600;
            }
          }
        }
        &:before {
          content: counters(item, ".") " ";
          counter-increment: item;
        }
        @include screen(tablet) {
          margin-left: 16px;
        }
      }
      @include screen(tablet) {
        margin-top: 12px;
      }
    }
    ul {
      li {
        margin-left: 8px;
        @include screen(tablet) {
          margin-left: 16px;
        }
      }
    }
    & > ol {
      & > li {
        margin-left: 0;

        font-weight: 600;
        font-size: 20px;
        line-height: 30px;

        transition: color $transition-default;
        &:before {
          content: counters(item, ".") ". ";
          counter-increment: item;
        }
      }
    }
  }
}