.hero {
  padding-top: 76px;
  &__bg {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 20;
    height: 36px;
    width: 36px;
    border-radius: 100%;
    background-color: $slate-50;
    transition: transform $transition-slow;
    @include screen(tablet-2x) {
      bottom: auto;
      right: auto;
      height: 80px;
      width: 80px;
    }
    &.active {
      transform: scaleX(100) scaleY(100);
      @include screen(tablet-2x) {
        transform: translate(50%, 50%) scaleX(50) scaleY(50);
      }
    }
  }
  &__pulse {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 20;
    height: 36px;
    width: 36px;
    border-radius: 100%;
    background-color: $slate-50;
    transition: transform $transition-slow;
    animation: toggleBg 1s infinite;
    @include screen(tablet-2x) {
      bottom: auto;
      right: auto;
      height: 80px;
      width: 80px;
    }
  }
  &__icon {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 30;
    display: flex;
    width: 36px;
    height: 36px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: $slate-800;
    transition: transform $transition-default,
      background-color $transition-default;
    @include screen(laptop) {
      &:hover {
        background-color: $blue-500;
      }
    }
    @include screen(tablet-2x) {
      bottom: auto;
      right: auto;
      width: 80px;
      height: 80px;
    }
    i {
      position: relative;
      margin-right: -1px;
      margin-top: -1px;
      font-size: 14px;
      line-height: 20px;
      color: $white;
      @include screen(tablet-2x) {
        margin-right: -4px;
        margin-top: -4px;
        font-size: 36px;
        line-height: 40px;
      }
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    @include screen(tablet-2x) {
      flex-direction: row;
      align-items: center;
    }
  }
  &__content {
    display: flex;
    min-height: calc(100dvh - 136px);
    max-width: 730px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @include screen(tablet) {
      min-height: calc(100vh - 186px);
      justify-content: center;
      width: 100%;
    }
    h1 {
      margin-bottom: 28px;
      font-size: 30px;
      line-height: 36px;
      font-weight: 800;
      @include screen(laptop) {
        font-size: 72px;
        line-height: 1;
        white-space: nowrap;
      }
    }
    p {
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 22px;
      @include screen(tablet) {
        font-size: 16px;
        line-height: 24px;
      }
      &:nth-child(2) {
        margin-bottom: 24px;
        font-weight: 600;
        @include screen(tablet) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      a {
        border-bottom: 2px solid transparent;
        font-weight: 500;
        color: $blue-500;
        @include screen(laptop) {
          transition: border-color $transition-default;
          &:hover {
            border-color: $blue-500;
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    span {
      &.html,
      &.css,
      &.javascript {
        position: relative;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        transition: border-color $transition-default;
        @include screen(tablet) {
          padding-bottom: 1px;
        }
        &:after {
          position: absolute;
          right: -6px;
          top: -7px;
          font-weight: 700;
          content: "*";
        }
      }
      &.html {
        &:hover {
          border-color: $orange-700;
        }
        &:after {
          color: $orange-700;
        }
      }
      &.css {
        margin-left: 5px;
        &:hover {
          border-color: $blue-700;
        }
        &:after {
          right: -8px;
          color: $blue-500;
        }
      }
      &.javascript {
        margin-left: 5px;
        margin-right: 5px;
        &:hover {
          border-color: $yellow-300;
        }
        &:after {
          right: -7ppx;
          color: $yellow-300;
        }
      }
      &.html-open {
        border-color: $orange-700;
        &:after {
          display: none;
        }
      }
      &.css-open {
        border-color: $blue-700;
        &:after {
          display: none;
        }
      }
      &.javascript-open {
        margin-right: 0;
        border-color: $yellow-300;
        &:after {
          display: none;
        }
      }
    }
  }
  &__button {
    position: relative;
    width: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    @include screen(tablet-2x) {
      display: flex;
      width: auto;
    }
  }
}

@keyframes toggleBg {
  0% {
    box-shadow: 0 0 0 0 rgba(30, 41, 59, 0.7);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(30, 41, 59, 0);
  }
}