.callback {
  position: fixed;
  right: 0;
  top: 0;
  z-index: -10;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  opacity: 0;
  background-color: $slate-50;
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__wrapper {
    position: relative;
    border-radius: 4px;
    background-color: $white;
    padding: 16px;
    margin: 0 16px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -2px rgba(0, 0, 0, 0.1);
    @include screen(mobile) {
      padding: 32px;
    }
  }
  &__error {
    visibility: hidden;
    position: absolute;
    bottom: -17px;
    left: 0;
    font-size: 12px;
    line-height: 16px;
    color: $red-500;
    color: #ef4444;
    opacity: 0;
    transition: visibility $transition-default, opacity $transition-default;
  }
  &__close {
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    height: 28px;
    width: 28px;
    align-items: center;
    justify-content: center;
    @include screen(tablet) {
      right: 20px;
      top: 20px;
    }
    i {
      cursor: pointer;
      font-size: 20px;
      line-height: 28px;
      transition: color $transition-default;
    }
    @include screen(laptop) {
      &:hover {
        i {
          color: $blue-500;
        }
      }
    }
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
  form {
    label {
      position: relative;
      margin-top: 20px;
      display: block;
      h3 {
        margin-bottom: 8px;
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: $gray-900;
      }
      input,
      textarea {
        display: block;
        width: 100%;
        border-radius: 8px;
        border: 1px solid $gray-300;
        background-color: $gray-50;
        padding: 10px;
        font-size: 14px;
        line-height: 20px;
        color: $gray-900;
        transition: color $transition-default;
        &.error {
          border-color: $red-500;
          & + .callback__error {
            visibility: visible;
            opacity: 1;
          }
        }
        &.valid {
          border-color: $blue-500;
        }
      }
      textarea {
        height: 80px;
        resize: none;
      }
    }
    .callback__policy {
      position: relative;
      display: inline-block;
      cursor: pointer;
      input {
        position: absolute;
        left: 4px;
        top: 2px;
        z-index: -1;
        width: auto;
        &:checked + p i {
          opacity: 1;
        }
      }
      p {
        position: relative;
        z-index: 20;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-left: 28px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: $gray-900;
        span {
          margin-right: 4px;
        }
        a {
          position: relative;
          color: $blue-500;
          &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: transparent;
            @include screen(laptop) {
              transition: background-color $transition-default;
            }
          }
          @include screen(laptop) {
            &:hover {
              &:before {
                background-color: $blue-500;
              }
            }
          }
        }
        &:before {
          content: "";
          position: absolute;
          left: 0;
          z-index: 20;
          height: 20px;
          width: 20px;
          top: 1px;
          border-radius: 4px;
          border: 1px solid $gray-300;
          background-color: $gray-50;
        }
        i {
          position: absolute;
          left: 5px;
          top: 1px;
          z-index: 30;
          font-size: 11px;
          opacity: 0;
          @include screen(laptop) {
            transition: opacity $transition-default;
          }
        }
      }
    }
    button {
      margin-top: 16px;
      display: block;
      width: 100%;
      border-radius: 8px;
      background-color: $slate-800;
      padding: 10px;
      text-align: center;
      color: $white;
      @include screen(laptop) {
        transition: background-color $transition-default;
        &:hover {
          background-color: $blue-500;
        }
      }
    }
  }
}